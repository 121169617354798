import React, { Fragment, Suspense } from 'react'
import {
  generateModalRoutes,
  generatePreservedRoutes,
  generateRegularRoutes,
} from '@generouted/react-router/core'
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useLocation,
  type ActionFunction,
  type LoaderFunction,
  type RouteObject,
} from 'react-router-dom'

type Element = () => JSX.Element
type Module = {
  default: Element
  Loader?: LoaderFunction
  Action?: ActionFunction
  Catch?: Element
  Pending?: Element
}

const PRESERVED = import.meta.glob<Module>('/src/pages/(_app|404).{jsx,tsx}', { eager: true })
const MODALS = import.meta.glob<Pick<Module, 'default'>>('/src/pages/**/[+]*.{jsx,tsx}', {
  eager: true,
})
const ROUTES = import.meta.glob<Module>(['/src/pages/**/[\\w[-]*.{jsx,tsx}', '!**/(_app|404).*'])

const preservedRoutes = generatePreservedRoutes<Omit<Module, 'Action'>>(PRESERVED)
const modalRoutes = generateModalRoutes<Element>(MODALS)

const Storage = window.localStorage
const PAGE_REFRESH_KEY = 'page-has-been-force-refreshed'

function lazyLoadPage(mod) {
  return React.lazy(async () => {
    try {
      const Page = await mod()

      Storage.setItem(PAGE_REFRESH_KEY, 'false')

      return Page
    } catch (error) {
      const pageHasAlreadyBeenForceRefreshed = JSON.parse(
        Storage.getItem(PAGE_REFRESH_KEY) ?? 'false'
      )

      if (!pageHasAlreadyBeenForceRefreshed) {
        Storage.setItem(PAGE_REFRESH_KEY, 'true')

        return window.location.reload()
      }

      throw error
    }
  })
}

const regularRoutes = generateRegularRoutes<RouteObject, () => Promise<Module>>(
  ROUTES,
  (module, key) => {
    const index =
      /index\.(jsx|tsx)$/.test(key) && !key.includes('pages/index') ? { index: true } : {}

    return {
      ...index,
      lazy: async () => {
        // let m: Module | null = null
        // try {
        //   m = await module()
        // } catch (e) {
        //   window.location.reload()
        // }

        const Element = lazyLoadPage(module)
        // const Element = m?.default || Fragment
        const Page = () => (
          <Suspense fallback={<div>loading...</div>}>
            <Element />
          </Suspense>
        )

        return {
          Component: Page,
          //   ErrorBoundary: m?.Catch,
          //   loader: m?.Loader,
          //   action: m?.Action,
        }
      },
    }
  }
)

const _app = preservedRoutes?.['_app']
const _404 = preservedRoutes?.['404']

const Element = _app?.default || Fragment
const App = () => {
  return _app?.Pending ? (
    <Suspense fallback={React.createElement(_app.Pending)}>
      <Element />
    </Suspense>
  ) : (
    <Element />
  )
}

const app = {
  Component: _app?.default ? App : Outlet,
  ErrorBoundary: _app?.Catch,
  loader: _app?.Loader,
}
const fallback = { path: '*', Component: _404?.default || Fragment }

export const routes: RouteObject[] = [{ ...app, children: [...regularRoutes, fallback] }]
export const Routes = () => <RouterProvider router={createBrowserRouter(routes)} />

export const Modals = () => {
  const Modal = modalRoutes[useLocation().state?.modal] || Fragment
  return <Modal />
}
