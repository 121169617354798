import React, { FunctionComponent, useState } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { httpLink } from '@trpc/client'
import superjson from 'superjson'

import { trpc } from '@/util/trpc'
import ContentBoxWrapper from './components/ContentBoxWrapper'
import '@mantine/core/styles.css'
import { MantineProvider } from '@mantine/core'

import { Routes } from './routes'

const App: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            useErrorBoundary: true,
          },
        },
      })
  )
  const [trpcClient] = useState(() =>
    trpc.createClient({
      transformer: superjson,
      links: [
        httpLink({
          url: import.meta.env.VITE_API_URL + 'trpc',
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: 'include',
            })
          },
        }),
      ],
    })
  )

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <MantineProvider>
          <Routes />
        </MantineProvider>
      </QueryClientProvider>
    </trpc.Provider>
  )
}

export default App
